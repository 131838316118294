
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.ct-chart-line {
  background-color: #eee;
}

.ct-label {
  color: black;
  font-size: small;
  line-height: 1;
}

.ct-series-a .ct-line {
  stroke: rgba(0,0,0,.4);
  stroke-width: 5px;
}

.ct-series-a .ct-point {
  stroke: grey;
  stroke-width: 15px;
  stroke-linecap: round;
}

.chartist-tooltip {  
  background: #808080;
  color: #ede1e1;   
}

  .chartist-tooltip:before {
      border-top-color: #808080;
  }


  .containers {
    width: 80%;
    height: 100%;
}

.containers {
    margin-left: 10%;
    font-size: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content:center;
    text-align: left;
}


.MuiTableRow {
  background-color: #61dafb;
}
.App {
    text-align: center;
  }  

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  
  .ct-chart-line {
    background-color: #eee;
  }
  
  .ct-label {
    color: black;
    font-size: small;
    line-height: 1;
  }
  
  .ct-series-a .ct-line {
    stroke: rgba(0,0,0,.4);
    stroke-width: 5px;
  }
  
  .ct-series-a .ct-point {
    stroke: grey;
    stroke-width: 15px;
    stroke-linecap: round;
  }
  
  .chartist-tooltip {  
    background: #808080;
    color: #ede1e1;   
  }
  
    .chartist-tooltip:before {
        border-top-color: #808080;
    }

    .App header {
      background-color: #282c34;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: white;
      padding: 0.5rem 1rem;
    }
    
    ul.menu {
      list-style: none;
    }
    
    ul.menu li {
      display: inline;
      padding: 12px;
    }
    
    ul.menu a {
      color: #ffffff;
    }
    
    .page {
      text-align: center;
    }
    
    button {
      background-color: #247484;
      color: #ffffff;
      border: none;
      padding: 10px 10px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
    }
    
    button:hover {
      background-color: #8ec7d1;
    }
    
    .warning {
      width: 800px;
      margin: 24px auto;
      border: 5px solid #f09191;
      border-radius: 15px;
    }
    
    .warning h1 {
      margin-top: 0;
      color: white;
      font-size: 48px;
      line-height: 96px;
      background-color: #662a2a;
    }
    
    .warning h2 {
      font-size: 36px;     
    }

    .greeting {
      width: 800px;
      margin: 24px auto;
      border: 5px solid #6af540;
      border-radius: 10px;
    }
    .greeting h1 {
      margin-top: 0;
      color: white;
      font-size: 48px;
      line-height: 96px;
      background-color: #6af540;
    }

    .tab-list {  
      border-width:0px 0px 0px 0px;
      padding-left:0 ;
    }
    
    .tab-list-item {
      font:inherit;
      display: inline-block;
      list-style: none;
      margin-bottom: -1px;
      padding:18px;
      
    }
    
    .tab-list-active {
      font:inherit;
      background-color: white;
      border: solid #247484;
      border-width: 0px 0px 4px 0px;
      color: #247484;
      font-weight: bold;
    }

    .container {
      margin-left: 4%;
      align-items: flex-start;
      text-align: left;

    }

.searchBar{
  border: 1px solid #ddd;
  width: 500px;
  padding-top: 6px;
  padding-bottom: 10px;
  height:35px;
}
.searchBar.invalid {
  border: 1px solid darkred;
}
.searchBarDiv p{
  display: none;
  color:darkred; 
  margin-top: 0;
}
.invalid p{  
  display: block;  
}
.hiddenColumn{  
  display: none;  
  width: 30px;
}
   .banner-text {
      word-wrap: break-word;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      background-color: #ac0a18;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
      opacity: 1;
      mix-blend-mode: normal;
      color: #fcf7f8;
      font-feature-settings: "liga" 0;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      --paragraph: 0px;
      text-align: center;
      text-transform: none;
      height: 20px;
      width: 100%;  
      pointer-events: all; 
      font-family: system-ui;   
      margin-bottom: 20px;  
  }

  .banner-text p{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    white-space: pre-wrap;
  }

.banner-text p:last-child {
    margin-bottom: 0!important;
}

.tab-content{
  margin-right: 10%;
}

.react-search-field{
  margin-top:20px;
  margin-bottom: 20px;
}

.tableLink{
  color: black;
  text-decoration: none;
}

.tableNotFoundHdr{
  margin-top:'6%';
  position:'0';
  top:'16%';
  margin-left:'70px';
  text-align:'center';
  font-size: 1.5em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.tableNotFoundHdr.warn{
  color:saddlebrown
}
.filterFormat{
  align-self: right;
}

.aboveTable{
  display:inline-block;
  width: auto;
}

.ur-selector-timeframe{
  margin-left:8%;
  min-width: 300px;
}

.css-2613qy-menu{
  position: absolute; 
  z-index:1000;
}

.headerTitle{
  font-weight: bold;
  font-size:20px;
  justify-content: center;
  margin-left:30%;
}

.userDetails{
text-align: right;
margin-left: 20%;
white-space: nowrap;
}
div.userDetails{
  padding-right: 5px;
}
.logoutButton{
  margin-left:0;
  margin-right: 5px;
}

.districtDetailTable{
  margin-left: 1%;
}

.dialogConfirmText{
  text-align: center;
  color: #ea7911f0;
  padding-bottom: 20px;
}

.MuiDialog-paperScrollPaper {
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
  border-style: double;
}


.masterSyncButton{
  display: inline;
  margin-left: auto;
  margin-right: 0px;
}

.aboveBlock{
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.hangfireDashboardbtn{
  margin-left: 5px;
}
.hoverText:hover{
  text-decoration: underline;
  cursor: pointer;
}
div.iAStyle{
  white-space: nowrap;
  padding-top: 0;
}
ul.noStyle{
  list-style-type: none;
  padding-left:0;
  font-size: 95%;
}
ul.iAStyle{
  list-style-type: disc;
  margin-left: 25%;
  font-size: 95%;  
}
li.iAStyle{
  text-align: left;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter>div {  
  display: block;
  text-align: center;
  position: relative;
  min-width: 0px;
}
li.MuiMenuItem-gutters
{
 display: block;
 justify-content: left;
 padding: 0 3px 0 5px;
}
li.MuiMenuItem-gutters:hover{
  text-decoration: underline;  
}
.homeBtnDiv{
  display:flex
}
.helpBtnDiv{
  padding-top: 6px;
}